import { PageWrapper } from '@coverright/ui/shared';
import * as React from 'react';
import { getCId, GetPlansYear } from '@coverright/utils';
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import { CRCheckbox, Tooltip, SelectInput } from '@coverright/ui/inputs';
import { PdpQuoteContext } from '@coverright/shared/contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { PdpField, PlanYear } from '@coverright/data-access/types/medicare';
import { MarketplaceDisclamers } from '@coverright/ui/marketplaces';
import { PdpPlanList } from './components/PdpPlanList';
import { UpdateZip } from '@coverright/widgets';

export default function Plans() {
  const urlParams = new URLSearchParams(window.location.search);
  const { zip, countyName } = useParams<{ zip: string; countyName: string }>();
  const [sort, setSort] = React.useState<PdpField>(PdpField.MonthlyCost);
  const [planYear, setPlanYear] = React.useState<PlanYear>(
    (urlParams.get('planYear') as PlanYear) || GetPlansYear()
  );
  const { insulinSavings, clientId, save, ...quote } =
    React.useContext(PdpQuoteContext);
  const navigate = useNavigate();

  const onUpdateZipModalClose = React.useCallback(
    (newZip?: string, newCounty?: string) => {
      if (
        quote.id &&
        newZip &&
        newCounty &&
        (newCounty !== countyName || newZip !== zip)
      ) {
        save({
          variables: {
            input: { id: quote.id, zip: newZip, countyName: newCounty },
          },
        }).then(
          () => (document.location.href = '/plans/' + newZip + '/' + newCounty)
        );
      }
    },
    [zip, countyName, quote.id]
  );

  return (
    <Container maxWidth={'md'}>
      <Button
        startIcon={<img src={'/assets/img/arrow-left.svg'} />}
        onClick={() => navigate(`/drugs/${zip}/${countyName}`)}
      >
        Back to drugs
      </Button>

      <Typography
        sx={{
          fontSize: { xs: 20, sm: 32 },
          lineHeight: { xs: '25px', sm: '40px' },
        }}
      >
        Here are your Prescription Drug Plan options.
      </Typography>

      <Box>
        <UpdateZip
          zip={zip}
          county={countyName}
          onChange={onUpdateZipModalClose}
        />
      </Box>

      <PageWrapper
        title={''}
        subtitle={''}
        maxWidth={700}
        hideHeader
        hideFooter
        hideDisclamer
        formName={'PDP Plan list'}
      >
        <Box sx={{ pb: 3 }}>
          <Typography className={'fs-16'}>Sort by:</Typography>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <div>
                <SelectInput
                  hideTick
                  className={'mb-0'}
                  value={sort}
                  onChange={(e) => setSort(e.target.value as PdpField)}
                >
                  <MenuItem value={PdpField.MonthlyCost}>Monthly Cost</MenuItem>
                  <MenuItem value={PdpField.Premium}>Premium</MenuItem>
                </SelectInput>
              </div>
              <Box display={'flex'} alignItems={'center'}>
                <CRCheckbox
                  variant={'standart'}
                  labelClassName={'fs-14 medium'}
                  controlClassName={'transparent'}
                  label={'Insulin savings'}
                  checked={!!insulinSavings}
                  onChange={() => {
                    save({
                      variables: {
                        input: {
                          countyName,
                          zip,
                          id: quote.id,
                          cId: getCId(),
                          insulinSavings: !insulinSavings,
                        },
                      },
                    });
                  }}
                />
                <Tooltip
                  placement={'top'}
                  arrow
                  title={
                    "Prescription Drug Plans that offer Insulin Savings are part of Medicare's Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit."
                  }
                >
                  <img
                    className={'w-16 h-16'}
                    src={'/assets/img/info-green.svg'}
                  />
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={'mr-12'}>2023</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={planYear === PlanYear.Year2024}
                    onChange={(e) =>
                      setPlanYear(
                        e.target.checked ? PlanYear.Year2024 : PlanYear.Year2023
                      )
                    }
                  />
                }
                label={'2024'}
              />
            </Box>
          </Box>
        </Box>
        <PdpPlanList
          zip={zip!}
          countyName={countyName!}
          sort={sort}
          planYear={planYear}
          clientId={clientId}
          insulinSavings={!!insulinSavings}
          preferredPharmacies={quote.preferredPharmacies}
          preferredDrugs={quote.preferredDrugs}
          onAddDrugsClick={(plan) => navigate(`/drugs/${zip}/${countyName}`)}
          onPharmacyClick={(plan) =>
            navigate(`/pharmacies/${zip}/${countyName}`)
          }
          onSelectClick={(plan) =>
            navigate(`/completed/${zip}/${countyName}/${plan.bidId}`)
          }
        />
      </PageWrapper>
      <MarketplaceDisclamers hideMultiplan zip={zip} />
    </Container>
  );
}
