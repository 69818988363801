import * as React from 'react';
import { CRModal } from '@coverright/ui/shared';
import { Props } from '../lib/types';
import { Box, Typography } from '@mui/material';
import { ModalCloseButton } from '@coverright/shared/ui';
import { PDPDrugCostBreakdown } from './pdp-drug-cost-breakdown';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

interface ModalProps extends Props {
  clientId?: string;
  open: boolean;
  onClose: () => void;
}

export function PdpDrugCostBreakdownModal(props: ModalProps) {
  const { open, onClose, ...rest } = props;
  return (
    <CRModal
      paperSx={{
        width: { xs: '100%', lg: '75%' },
        minWidth: { md: 800 },
        maxWidth: 1100,
      }}
      open={open}
      onClose={onClose}
      paperPadding={{ xs: 2, md: '32px 16px 32px 32px' }}
      disableSidePadding
    >
      <OverlayScrollbarsComponent
        options={{ paddingAbsolute: true, scrollbars: {autoHide: 'move' } }}
        style={{paddingRight: 16}}
        defer
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pb: 3 }}>
          <Typography variant="h3" sx={{ flexGrow: 1 }}>Estimated Drug Cost Summary</Typography>
          <ModalCloseButton
            onClick={onClose}
          />
        </Box>
        <PDPDrugCostBreakdown {...rest} />
      </OverlayScrollbarsComponent>
    </CRModal>
  );
}
